import { Form } from '@remix-run/react';

import { Button } from '@repo/ui/components';
import { cn } from '@repo/ui/utils';
import { type LoaderFunctionArgs } from '@remix-run/node';
import { FeatureItem, ShorebirdLogo } from '~/components';
import { authenticator } from '~/services/auth.server';

export const loader = async ({ request }: LoaderFunctionArgs) => {
  return authenticator.isAuthenticated(request, {
    successRedirect: '/',
  });
};

export default function Login() {
  const features = [
    'Integrate in minutes',
    'Push updates to any Dart code',
    'Supports Android, iOS, macOS, and Windows',
  ];

  return (
    <div className="mx-auto w-10/12 max-w-xl sm:mt-20">
      <div>
        <ShorebirdLogo />
      </div>
      <h3 className="mt-3 text-4xl font-bold">Deploy Updates Instantly</h3>
      <p className="mt-3">
        Code push is a cloud service that allows developers to push app updates
        directly to users&apos; devices.
      </p>

      <div className="divider"></div>

      <ul>
        {features.map((feature) => (
          <FeatureItem key={feature} description={feature} />
        ))}
      </ul>

      <div className="divider"></div>

      <SocialLoginButtons />
    </div>
  );
}

export function SocialLoginButtons({
  className,
  ...props
}: React.ComponentPropsWithoutRef<'div'>) {
  return (
    <div className={cn('flex flex-col gap-4', className)} {...props}>
      <Form action="/auth/google" method="post">
        <Button
          variant="outline"
          className="w-full hover:text-accent hover:border-accent"
        >
          <svg viewBox="0 0 24 24" className="mr-2 h-5 w-5">
            <path
              d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z"
              fill="#4285F4"
            />
            <path
              d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z"
              fill="#34A853"
            />
            <path
              d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z"
              fill="#FBBC05"
            />
            <path
              d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z"
              fill="#EA4335"
            />
            <path d="M1 1h22v22H1z" fill="none" />
          </svg>
          Continue with Google
        </Button>
      </Form>
      <Form action="/auth/microsoft" method="post">
        <Button
          variant="outline"
          className="w-full hover:text-accent hover:border-accent"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 23 23"
            className="mr-2 h-5 w-5"
          >
            <path fill="#f35325" d="M1 1h10v10H1z" />
            <path fill="#81bc06" d="M12 1h10v10H12z" />
            <path fill="#05a6f0" d="M1 12h10v10H1z" />
            <path fill="#ffba08" d="M12 12h10v10H12z" />
          </svg>
          Continue with Microsoft
        </Button>
      </Form>
      <div className="text-balance text-center text-xs [&_a]:underline [&_a]:underline-offset-4 hover:[&_a]:text-accent">
        By clicking continue, you agree to our{' '}
        <a href="https://shorebird.dev/terms">Terms of Service</a> and{' '}
        <a href="https://shorebird.dev/privacy">Privacy Policy</a>.
      </div>
      <div className="text-balance text-center text-xs [&_a]:underline [&_a]:underline-offset-4 hover:[&_a]:text-accent">
        Need a different way to login?{' '}
        <a href="mailto:contact@shorebird.dev">Let us know</a> and we are happy
        to help!
      </div>
    </div>
  );
}
